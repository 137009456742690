document.addEventListener('DOMContentLoaded', () =>{

  const applicationFormContainer = document.querySelector(".application-form");
  
  if (applicationFormContainer) {
    const applicationForm = applicationFormContainer.closest('form');

    let changed = false;

    const markAsChanged = () => changed = true;
    const markAsUnchanged = () => changed = false;

    applicationForm.addEventListener('input', markAsChanged);
    applicationForm.addEventListener('select2:change', markAsChanged);
    applicationForm.addEventListener('submit', markAsUnchanged);

    const cancelApplicationButton = document.querySelector('#confirm-cancel .btn-primary');
    if (cancelApplicationButton)
      cancelApplicationButton.addEventListener('click', markAsUnchanged);

    window.onbeforeunload = function () {
      if(changed) {
        return 'Your changes will be lost unless you click "Save and continue".';
      }
    }
  }
});

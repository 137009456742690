import Rails from "@rails/ujs";

document.body.addEventListener('select2:change', () => {
  const { target } = event;

  if (target && target.id === 'course-select') {
    const selectedOption = target.options[target.selectedIndex];
    document.querySelector('#course-entry-dates').innerHTML = selectedOption ? selectedOption.dataset.dateOfEntryFieldsTemplate : '';

    if (target.dataset.url) {
      Rails.ajax({
        url: `${target.dataset.url}?${new URLSearchParams({ course_id: selectedOption.value }).toString()}`,
        dataType: 'script',
        type: 'GET'
      });
    }
  }
})
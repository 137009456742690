import Rails from "@rails/ujs";

$(function(){
  // Re-enable submit button after 5 seconds if something has gone wrong.
  $(document).on('click', 'a[data-disable-with], input[data-disable-with], button[data-disable-with]', function(){
    if ($(this).not('[data-do-not-reenable]')) {
      const element = $(this);
      setTimeout(function () {
        Rails.enableElement(element[0]);
        element.prop('disabled', false);
      }, 10000);
    }
  });
});

import Rails from "@rails/ujs";
import { Modal } from 'bootstrap';

const confirmationConditionCheckers = {
  'mature-course': () => {
    const courseSelect = document.querySelector('#course-select');
    return courseSelect &&
      courseSelect.dataset.mature &&
      courseSelect.options[courseSelect.selectedIndex].dataset.matureLearnerFoundationYear &&
      !JSON.parse(courseSelect.dataset.mature) &&
      JSON.parse(courseSelect.options[courseSelect.selectedIndex].dataset.matureLearnerFoundationYear);
  },

  'address-completed': () => {
    const addressLine1 = document.querySelector('#address-line-1');
    const addressLineCity = document.querySelector('#address-line-city');
    const addressLineCountry = document.querySelector('#address-line-country');

    return (addressLine1 && addressLine1.value === '') || (addressLineCity && addressLineCity.value === '') || (addressLineCountry && addressLineCountry.value === '');
  },

  'ucas-completed': () => {
    const alreadyThroughUcasOption = document.querySelector('[name$="[already_through_ucas]"][value="true"]');
    const ucasId = document.querySelector('[name$="[ucas_id]"]');

    return alreadyThroughUcasOption && alreadyThroughUcasOption.checked && ucasId && ucasId.value === '';
  },

  'remote': async () => {
    const confirmBox = document.querySelector('.confirm-box');

    return new Promise((resolve) => {
      Rails.ajax({
        url: confirmBox.dataset.url,
        type: 'GET',
        success: (data) => {
          resolve(data.result);
        }
      })
    })
  }
}

class ConfirmableForm {
  constructor(form) {
    this.form = form;

    this.confirmBox = document.querySelector('.confirm-box');
    this.confirmLink = this.confirmBox.querySelector('.confirm-link');

    this.confirmationNeeded = true;
    this.confirmed = false;

    this.bindEvents();
  }

  conditionChecker = () => (confirmationConditionCheckers[this.confirmBox.dataset.conditionChecker] || (() => true))();

  checkCondition = async () => {
    const { confirmBox, conditionChecker, form } = this;
    const confirmationNeeded = await conditionChecker();

    if (confirmationNeeded) {
      Modal.getOrCreateInstance(confirmBox).show();
    } else {
      this.confirmationNeeded = false;
      form.submit();
    }
  }

  bindEvents = () => {
    const { confirmBox, form, checkCondition, confirmationNeeded, confirmed, confirmLink } = this;

    confirmBox.addEventListener('hidden.bs.modal', () => Rails.enableElement(form.querySelector('button[type="submit"]')));
    form.addEventListener('submit', (event) => {
      checkCondition();
      if (confirmationNeeded && !confirmed) {
        event.preventDefault();
      }
    });

    confirmLink.addEventListener('click', (event) => {
      event.preventDefault();
      this.confirmed = true;
      form.submit();
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const confirmBox = document.querySelector(".confirm-box");
  if (confirmBox) {
    const confirmableForm = confirmBox.closest('form');
    new ConfirmableForm(confirmableForm);
  }
});

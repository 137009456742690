import Rails from "@rails/ujs";
import 'bootstrap';
import '@nathanvda/cocoon';

import '../scripts/select2_init';
import '../scripts/select2_visibility_map_patch';
import '../scripts/tooltip_and_popover_init';
import '../scripts/submit_on_change';
import '../scripts/btn_feedback_hack';
import '../scripts/confirm_form';
import '../scripts/available_courses';
import '../scripts/application_form';
import '../scripts/option_filter';

import '../scripts/autocomplete_email';

import { AjaxModal, ConfirmModal, AutoUpdate, FlashMessage, VisibilityMap } from '@epigenesys/epi-js';

window.FlashMessage = FlashMessage;

FlashMessage.template = '<div class="alert alert-dismissible fade show" role="alert"><div class="container"><span class="flash-message-content"></span><button type="button" class="btn btn-link p-0" data-bs-dismiss="alert" title="Hide this message">Dismiss</button></div></div>'

AjaxModal.start();
VisibilityMap.start();
AutoUpdate.start();

ConfirmModal.options.cancelButtonClass = 'btn btn-default';

Rails.confirm = function (message, element) {
  return ConfirmModal.confirm(message, element);
}

Rails.start();

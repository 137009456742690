import 'select2';

$(document).on('init-select2', '.select2-select', function () {
  let placeholder, width;
  let options = {
    theme: 'bootstrap-5',
    placeholder: ((placeholder = $(this).attr('placeholder')) !== undefined ? placeholder : 'Please select an option'),
    allowClear: ($(this).data('allow-clear') !== undefined) ? true : false,
    width: ((width = $(this).data('width')) !== undefined ? width : '100%')
  };

  const ajaxModalWindow = document.querySelector('#modalWindow');

  if (ajaxModalWindow) {
    options.dropdownParent = $(ajaxModalWindow)
  }

  $(this).select2(options);
});

$(document).on('init-grade-selection', 'select.grade-selection', function () {
  let placeholder;
  $(this).select2({
    placeholder: ((placeholder = $(this).attr('placeholder')) !== undefined ? placeholder : 'Please select an option'),
    theme: 'bootstrap-5',
    width: '100%',
    templateResult(grade) {
      if (grade.element !== undefined) {
        const $option = $(grade.element);
        const name = $option.data('name');
        const phonetics = $option.data('phonetics');
        if (name !== undefined) {
          return `${name}<br /><small class='text-muted'>${phonetics}<small>`;
        } else {
          return grade.text;
        }
      }
    },
    templateSelection(grade) {
      if (grade.element !== undefined) {
        const $option = $(grade.element);
        const name = $option.data('name');
        const phonetics = $option.data('phonetics');
        if (name !== undefined) {
          if (!!phonetics) {
            return `${name} <small class='text-muted'>(${phonetics})<small>`;
          } else {
            return name;
          }
        } else {
          return grade.text;
        }
      } else {
        return grade.text;
      }
    },
    escapeMarkup(markup) {
      return markup;
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
  $('.select2-select').trigger('init-select2');
  $('select.grade-selection').trigger('init-grade-selection');
  $(document).on('cocoon:after-insert', (e, insertedItem) => $(insertedItem).find('.grade-selection').trigger('init-grade-selection'));
})

document.body.addEventListener('show.bs.modal', () => {
  $('#modalWindow select.select2-select').trigger('init-select2');
});

$(document).on('change.select2', (event) => {
  event.target.dispatchEvent(new Event('select2:change', { bubbles: true }));
})
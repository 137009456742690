// Select2 does not fire browser's native `change` event. Instead we get it to fire a custom event and listen to it instead

import { ElementMap, VisibilityMap } from '@epigenesys/epi-js';

document.addEventListener('select2:change', (event) => {
  const { target } = event;

  if (target && target.hasAttribute('data-visibility-map')) {
    ElementMap.getOrCreateInstance(VisibilityMap, target).updateVisibility();
  }
});
document.addEventListener('DOMContentLoaded', () => {
  const dualNationalityField = document.querySelector('.dual_nationality_wrapper');

  if (dualNationalityField) {
    document.addEventListener('visibility.show', (event) => {
      const { target } = event;
      const dualNationalityFieldContainer = target.querySelector('.dual-nationality-field-container')

      if (dualNationalityFieldContainer) {
        dualNationalityFieldContainer.insertAdjacentElement('afterbegin', dualNationalityField);
      }
    });
  }
});
class EmailDomainSuggester {
  static domains = [
    "gmail.com", "hotmail.com", "icloud.com", "yahoo.com", "aol.com",
    "live.com", "googlemail.com", "msn.com", "hotmail.co.uk",
    "yahoo.co.uk", "mac.com", "me.com", "mail.com"
  ];

  constructor(element) {
    this.element = element;
    this.datalist = undefined;
    this.addElements();
    this.bindEvents();
  }

  addElements = () => {
    const datalistId = `domain-options-${this.element.id}`;
    const datalist = document.createElement('datalist');

    datalist.setAttribute('id', datalistId);
    this.element.insertAdjacentElement('afterend', datalist);

    this.datalist = datalist;
    this.element.setAttribute('list', datalistId)
  }

  bindEvents = () => {
    this.element.addEventListener('input', this.autocomplete);
  }

  autocomplete = () => {
    const value = this.element.value;
    this.emptyDatalist();

    if (value.indexOf("@") != -1) {
      this.addDatalist(value.split("@")[0]);
    }
  }

  addDatalist = (value) => {
    EmailDomainSuggester.domains.forEach((domain) => {
      const optionElement = document.createElement('option');
      optionElement.textContent = `${value}@${domain}`;
      this.datalist.appendChild(optionElement);
    });
  }

  emptyDatalist = () => {
    this.datalist.innerHTML = '';
  }
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('input.email[type="email"]').forEach((element) => new EmailDomainSuggester(element));
});
